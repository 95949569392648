.header {
    font-weight: bolder;
    color: #334155;
    margin-top: 30px;
    margin-bottom: 20px;
    font-family: "Segoe UI", serif;
}

.wrapper {
    min-height: 100vh;
}

.daily-summary-wrapper {
    /*border: 1px solid black;*/
    /*max-width: 360px;*/
    /*padding:5px;*/
    cursor: pointer;
    border-radius: 20px;
}

.daily-summary-wrapper-text {
    text-align: left;
    background:transparent;
    border: none;
    /*padding-left: 10px;*/
    font-family: "Segoe UI", serif;
    user-select: none;

}

.daily-summary-wrapper-text:focus {
    outline: 0;
}
.daily-summary-wrapper-text:active {
    background-color: rgba(111,148,182,0.1);
}
