.root {
    width: 100%;
}
@media (min-width: 640px) {
    .root {
        padding-left: 0;
        padding-right: 0;
        max-width: 24rem;
    }
}

.body {
    width: 100%;
    max-height: 11rem;
    resize: none;
    border:none;
    font-size: 14px;
    font-family: "Segoe UI", serif
}

.body:focus {
    outline: none;
}

.title {
    width: 100%;
    border:none;
    font-size: 16px;
    resize: none;
}

.title:focus {
    outline: none;
}

.title::placeholder {
    font-weight: normal;
    font-family: "Segoe UI", serif
}
